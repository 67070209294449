<template>
  <div class="tab-pane active" id="live-stats">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6">
          <SentChart />
        </div>
        <div class="col-lg">
          <div class="row enviadas stats-column-right pr-5">
            <div
              id="infos-top"
              class="card card-messages"
            >
              <div class="card-body attendance">
                <Attendance />
                <p class="card-text">{{$t('attendance.finished')}}</p>
                <span class="card-text">100Mil</span>
              </div>
            </div>
          </div>
          <div class="row recebidas stats-column-right pr-5">
            <div
              id="infos-bottom"
              class="card card-messages"
            >
              <div class="card-body">
                <PhoneDash />
                <p class="card-text">{{$t('attendance.abandoned')}}</p>
                <span class="card-text">100Mil</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <StatusChart />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import SentChart from '@/components/rebranding/apexcharts/SentChart.vue';
import StatusChart from '@/components/rebranding/apexcharts/StatusChart.vue';
import Attendance from '@/components/rebranding/animLottie/Attendance.vue';
import PhoneDash from '@/components/rebranding/animLottie/PhoneDash.vue';
import $ from 'jquery';

export default {
  name: 'LiveStats',
  components: {
    SentChart,
    StatusChart,
    Attendance,
    PhoneDash,
  },
  directives: {
    Tab,
  },
  /* mounted() {
    setTimeout(() => {
      $('#infos-top').on('click', () => {
        $('.enviadas .card-second-row').toggleClass('active');
        $('.enviadas .card-second-row-2').toggleClass('active');
      });
      $('#infos-bottom').on('click', () => {
        $('.recebidas .card-second-row').toggleClass('active');
        $('.recebidas .card-second-row-2').toggleClass('active');
      });
    }, 300);
  }, */
};
</script>

<style lang="scss" scoped>
.stats-column-right {
  display: flex;
  flex-wrap: wrap;
}
.card-messages {
  margin-left: auto;
  max-height: 230px;

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: var(--clr-light-2);
      font-weight: bold;
    }
  }
}
#infos-bottom,
#infos-top {
  position: relative;
  z-index: 1;
  min-width: 350px;
}
.card-messages.inner-card {
  position: absolute;
  right: 0;
  opacity: 0;
}
.card-messages.inner-card.active {
  position: absolute;
  right: 325px;
  z-index: 0;
  opacity: 1;
}
.card-messages.inner-card.active:last-of-type {
  right: 470px;
}

.darkmode .card {
  background-color: var(--background-3);
}

/* Lottie */
::v-deep .attendance .svg_wrapper {
  margin-right: auto !important;
}
</style>
